<template>
	<div>
		<div class="left bottom-li border-right" element-loading-background="rgba(255, 255, 255, 1)" id="targetbox"
			element-loading-text="消息加载中..." v-loading="loading">
			<div class="chat-box">
				<ul class="chat-list">
					<li :class="[current==item.id?'current':'']" @click="binchat(item.id,item)"
						v-for="(item,index) in courseData" :key="index">
						<span></span>
						<div class="img">
							<el-image fit="fill" :src="item.avatar"></el-image>
						</div>
						<div class="name">{{item.name}}</div>
						<div class="num" v-if="item.no_read_count>0&&current!=item.id">{{item.no_read_count}}</div>
					</li>
					<div class="more">
						<div v-if="courseData.length==0">~暂无数据啦~</div>
						<el-button @click="goMore" :loading="loadingMore" v-if="showLoadMore&&courseData.length>20"
							round color="#f2f2f2" style="color: #333333;" type="primary">
							加载更多</el-button>
						<div v-if="!showLoadMore&&courseData.length>20">~加载到底啦~</div>
					</div>
				</ul>

			</div>
		</div>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/index";
	export default {
		props: ['userid'],
		data() {
			return {
				loading: false,
				loadingMore: false,
				showLoadMore: true,
				current: '',
				params: {
					page: 1,
					limit: 30,
					keyword: ''
				},
				lock: false,
				courseData: []
			}
		},
		watch: {
			userid(val) {
				this.current = val
			}
		},
		mounted() {
			this.current = this.userid
			this.getconfigs()
		},
		methods: {
			// 获取配置
			getconfigs() {
				this.$http.post(api.configs)
					.then((res) => {
						this.params.limit = res.data.max_service
						this.getList()
					})
			},
			// 搜索
			search(val) {
				this.loading = true
				this.lock = false
				this.params.page = 1
				this.params.keyword = val
				this.getList()
			},
			// 刷新
			Refresh() {
				this.lock = false
				this.params.page = 1
				this.getList()
			},
			// 点击聊天获取记录
			binchat(id, item) {
				this.current = id
				this.$emit('BinChat', JSON.stringify(item))
			},
			// 加载更多
			goMore() {
				this.loadingMore = true
				this.getList()
			},
			// 获取聊天记录
			async getList() {
				if (this.lock) {
					return
				}
				this.lock = true
				this.getdata()
			},
			// 获取接口
			async getdata() {
				this.showLoadMore = true;
				this.$http.post(api.userLists, this.params)
					.then((res) => {
						const list = this.courseData
						let page = this.params.page
						let lock;
						if (res.data.length < this.params.limit) {
							lock = true
							this.showLoadMore = false
						} else {
							this.showLoadMore = true
							page += 1
							lock = false
						}
						let listdata = this.params.page > 1 ? this.courseData.concat(res.data) : res.data
						this.courseData = listdata
						this.params.page = page
						this.lock = lock
						this.loadingMore = false
						var that = this
						setTimeout(function() {
							that.loading = false
						}, 800)
					})
			},
		}
	}
</script>

<style lang="scss">
	.more {
		text-align: center;
		margin: 10px 0;
	}

	.chat-text {
		width: 100%;
		display: flex;

		.chat-con {
			max-width: 320px;
			padding: 10px;
			margin: 0 15px;
			border-radius: 10px;
			background: #f5f5f5;
		}
	}

	.chat-li {
		width: 100%;

		.chat-left {
			width: 100%;

			.time {
				padding: 20px 0;
				text-align: center;
				color: #999999;
			}
		}
	}

	.chat-left-img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.emjjo-list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		overflow-y: scroll;
		max-height: 300px;

		p {
			cursor: pointer;
			width: 20%;
			text-align: center;
			font-size: 20px;
			margin-bottom: 10px;
		}
	}

	.send-tn {
		position: absolute;
		right: 20px;
		bottom: 20px;
		width: 80px;
	}

	.content-top {
		border-bottom: 1px solid #F2f2f2;
		height: 454px;
		padding: 20px;
		overflow-y: scroll;
	}

	.send {
		height: 190px;
		position: relative;
		padding: 0 20px;

		textarea {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			height: 190px;
		}
	}

	.emjjo {
		padding: 0 20px;
		height: 40px;
		display: flex;
		align-items: center;

		.text {
			margin-left: 50px;
			cursor: pointer;
		}

		i {
			cursor: pointer;
			width: 22px;
			height: 22px;
			display: inline-block;
			background-size: 100% 100%;
		}
	}

	.go-end {
		position: absolute;
		bottom: 0px;
		padding: 20px;
		width: 100%;

		button {
			width: 100%;
		}
	}

	.user-info-li {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 12px 20px;

		.names {
			flex: 1;
		}
	}

	.body {
		width: 100%;
		height: 100vh;

		.user-info {
			flex: 1;
			font-size: 16px;
			color: #FFFFFF;
			text-align: center;
		}

		.body-bg {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			flex-wrap: wrap;
			width: 100%;

			.constant {
				width: 1200px;
				display: flex;
				height: 750px;
				flex-wrap: wrap;
				background: #FFFFFF;
				box-shadow: 0 5px 40px 0 #777777;

				.bottom {
					width: 100%;
					overflow: hidden;
					height: 684px;
					display: flex;

					.content {
						height: 684px;
					}

					.chat-box {
						.chat-list {
							max-height: 684px;
							overflow-y: auto;

							.current {
								background: #eff0f1;

								span {
									background: #4ba6df;
								}
							}

							li {
								position: relative;
								width: 100%;
								cursor: pointer;
								padding: 12px 20px;
								height: 74px;
								display: flex;
								cursor: pointer;
								font-size: 14px;
								align-items: center;

								.num {
									width: 20px;
									height: 20px;
									text-align: center;
									line-height: 20px;
									font-size: 10px;
									text-align: center;
									background: red;
									color: #FFFFFF;
									border-radius: 50%;
								}

								.name {
									flex: 1;
								}

								span {
									position: absolute;
									left: 0;
									top: 50%;
									margin-top: -20px;
									width: 3px;
									height: 40px;
									display: inline-block;
									background: #FFFFFF;
								}

								.img {
									width: 40px;
									overflow: hidden;
									margin-right: 10px;
									border-radius: 50%;
									height: 40px;

									img {
										width: 40px;
										height: 40px;
									}
								}
							}
						}
					}

					.bottom-li {
						height: 684px;
					}

					.border-right {
						border-right: 1px solid #f2f2f2;
					}
				}

				.top {
					background: #4ba6df;
					height: 66px;
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;

					.top-li {
						display: flex;
						align-items: center;
						padding: 20px;
						box-sizing: border-box;

						.login-out {
							height: 35px;
							background: #FFFFFF;
							width: 90px;
							cursor: pointer;
							border-radius: 200px;
							line-height: 35px;
							text-align: center;
						}

						.user {
							flex: 1;
							display: flex;
							color: #FFFFFF;
							align-items: center;

							.user-img {
								width: 40px;
								height: 40px;
								margin-right: 10px;
								border-radius: 50%;
							}
						}

						.search {
							background: #FFFFFF;
							height: 35px;
							width: 100%;
							padding: 0 15px;
							display: flex;
							align-items: center;
							border-radius: 200px;

							i {
								margin-right: 5px;
								cursor: pointer;
							}

							input {
								font-size: 14px;
								flex: 1;
								height: 35px;
							}
						}
					}
				}

				.left {
					width: 319px;
				}

				.content {
					width: 601px;
				}

				.right {
					position: relative;
					width: 280px;
				}
			}
		}
	}
</style>
