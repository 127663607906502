<template>
	<div>
		<el-dialog draggable width="55%" top="5vh" :destroy-on-close="true" v-model="seeouterVisible" title="办事指南">
			<div class="search">
				<i class="icon iconfont icon-sousuo"></i>
				<input type="text" @keyup.enter="search" v-model="form.keyword" placeholder="请输入关键字进行查找" />
				<el-button @click="search" round color="#4ba6df" type="primary">
					搜索</el-button>
				<el-button @click="reset" round type="danger">
					清空</el-button>
			</div>
			<div class="dialog">
				<div class="table">
					<el-table ref="multipleTableRef" border element-loading-text="加载中..." v-loading="loading"
						:data="tableData" @selection-change="handleSelectionChange">
						<el-table-column width="80" type="selection" />
						<el-table-column type="index" width="80" label="序号" />
						<el-table-column property="name" label="办事指南标题" />
					</el-table>
				</div>
			</div>
			<div class="pages">
				<el-pagination background v-model="form.page" :page-size="15" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="seeouterVisible = false">关闭</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitWord">发送
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/index";
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				loading: false,
				total: 0,
				seeouterVisible: false,
				ruleForm: {
					originalname: '',
				},
				tableData: [],
				form: {
					keyword: '',
					page: 1,
					limit: 15,
				},
				formList: [],
				// newlist: []

			}
		},
		mounted() {

		},

		methods: {
			// 选择
			handleSelectionChange(e) {
				this.formList = []
				this.formList = e
			},
			// 清空
			reset() {
				this.form.keyword = ''
				this.form.page = 1
				this.getList()
			},
			// 搜索
			search() {
				this.tableData = []
				this.form.page = 1
				this.getList()
			},
			// 分页
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 获取列表
			getList() {
				let that = this
				this.loading = true
				this.$http.post(api.worklist, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data
							this.total = res.count

							// 选中后打开 默认选中
							// if (this.newlist.length > 0) {
							// 	this.newlist.forEach(row => {
							// 		this.$nextTick(() => {
							// 			this.$refs.multipleTableRef.toggleRowSelection(row, true)
							// 		})
							// 	})
							// }

							setTimeout(function() {
								that.loading = false
							}, 200)
						}
					})
			},
			SubmitWord() {
				if (this.formList.length == 0) {
					ElMessage({
						message: '~请选择办事指南~',
						type: 'warning',
					})
				} else {
					this.seeouterVisible = !this.seeouterVisible
					this.$emit('SubmitWord', this.formList)
					this.formList = []
				}

			},
			// 显示
			show() {
				this.form.page = 1
				this.form.limit = 15
				this.getList()
				// if (list.length > 0) {
				// 	this.newlist = list
				// }
				this.seeouterVisible = !this.seeouterVisible
			}
		}
	}
</script>

<style scoped lang="scss">
	.search {

		height: 35px;
		overflow: hidden;
		padding: 0 15px;
		display: flex;
		position: relative;
		align-items: center;
		margin-bottom: 20px;

		i {
			left: 30px;
			position: absolute;
			margin-right: 5px;
			cursor: pointer;
		}

		input {
			border-radius: 200px;
			padding-left: 40px;
			background: #F5f5f5;
			font-size: 14px;
			flex: 1;
			margin-right: 20px;
			height: 35px;
		}
	}

	.more {
		text-align: center;
		padding-bottom: 12px;
	}

	.dialog {
		height: 500px;
		overflow-y: auto;

		.dialog-li {
			cursor: pointer;
			padding-bottom: 12px;
			line-height: 22px;
		}
	}
</style>
