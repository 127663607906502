import request from './request'

const api = {
	// 客服获取聊天记录
	chatLog: '/v1/kefu/chatLog',
	// 办事指南
	getWords: '/v1/kefu/getWords',
	// 客服详情
	info: '/v1/kefu/info',
	// 用户对话列表（防止刷新对话列表不见）
	userLists: '/v1/kefu/userList',
	// 客服把用户消息设为已读
	resetRead: '/v1/kefu/resetKefuRead',
	// ip查找地址
	getCity: '/v1/kefu/getCity',
	// 客户获取办事指南列表
	worklist: '/v1/kefu/worklist',
	// 客服配置信息
	configs: '/v1/kefu/configs'
}

export {
	request,
	api
}
