<template>
	<div>
		<el-dialog top="5vh" draggable width="55%" :destroy-on-close="true" v-model="seeouterVisible" title="全部常用语">
			<div class="dialog">
				<div class="dialog-li" @click="bindtext(item.content)" v-for="(item,index) in courseData" :key="index">
					<div>{{item.content}}</div>
					<img src="../../../assets/img/gengduo.png" />
				</div>
				<div class="more">
					<div v-if="courseData.length==0">~暂无数据啦~</div>
					<el-button v-if="showLoadMore&&courseData.length>15" @click="goMore" :loading="loadingMore" round
						color="#f2f2f2" style="color: #333333;" type="primary">
						加载更多</el-button>
					<div v-if="!showLoadMore&&courseData.length>15">~加载到底啦~</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/index";
	export default {
		data() {
			return {
				loadingMore: false,
				showLoadMore: true,
				seeouterVisible: false,
				params: {
					page: 1,
					limit: 20
				},
				lock: false,
				courseData: []
			}
		},

		methods: {
			// 点击常用语
			bindtext(text) {
				this.seeouterVisible = false
				this.$emit('BindText', text)
			},
			// 加载更多
			goMore() {
				this.loadingMore = true
				this.getList()
			},
			// 获取常用语
			async getList() {
				if (this.lock) {
					return
				}
				this.lock = true
				this.getdata()
			},
			// 获取接口
			async getdata() {
				this.showLoadMore = true;
				this.$http.post(api.getWords, this.params)
					.then((res) => {
						const list = this.courseData
						let page = this.params.page
						let lock;
						if (res.data.length < this.params.limit) {
							lock = true
							this.showLoadMore = false
						} else {
							this.showLoadMore = true
							page += 1
							lock = false
						}
						let listdata = this.params.page > 1 ? this.courseData.concat(res.data) : res.data
						this.courseData = listdata
						this.params.page = page
						this.lock = lock
						this.loadingMore = false
					})
			},
			SubmitForm() {

			},
			// 显示
			show() {
				this.seeouterVisible = !this.seeouterVisible
				this.getList()
			}
		}
	}
</script>

<style scoped lang="scss">
	.more {
		text-align: center;
		padding-bottom: 12px;
	}

	.dialog {
		height: 500px;
		overflow-y: auto;

		.dialog-li {
			cursor: pointer;
			padding-bottom: 12px;
			line-height: 22px;
			display: flex;
			align-items: center;

			div {
				flex: 1;
				padding-right: 15px;
				line-height: 23px;
			}

			img {
				width: 18px;
				height: 18px;
			}
		}
	}
</style>
