const s_time = function (obj) { 
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date()
    	.getMonth() + 1)
    const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
    const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
    const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
    const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
    const week = new Date().getDay()
    const timeDate = month + '月' + date + '日 ' + hh + ':' + mm
    const Time = new Date(timeDate);
    const timestemp = Time.getTime();
    return timeDate
}
export default { 
    s_time
}