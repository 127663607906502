<template>
	<div class="body">
		<div class="body-bg">
			<div class="constant">
				<div class="top">
					<div class="left top-li">
						<div class="search">
							<i @click="search" class="icon iconfont icon-sousuo"></i>
							<input type="text" @keyup.enter="search" v-model="keyword" placeholder="请输入名称" />
							<el-button @click="search" round color="#4ba6df" style="color: #fff; height: 30px;"
								type="primary">
								搜索</el-button>
						</div>
					</div>
					<div class="content top-li">
						<div class="user">
							<el-image v-if="userInfo.avatar" class="user-img" fit="fill" :src="userInfo.avatar">
							</el-image>
							<view>{{userInfo.name}}</view>
							<view class="state">
								<span v-if="userInfo.online==1">在线</span>
								<span v-if="userInfo.online==0">已下线</span>
							</view>
						</div>
						<el-button @click="signout" round color="#ffffff" style="color: #333333;" type="primary">
							下班退出</el-button>

					</div>
					<div class="right top-li">
						<div class="user-info">用户信息</div>
					</div>
				</div>
				<div class="bottom">
					<!-- 用户列表 -->
					<Userlist ref="Userlist" :userid="userInfo.id" @BinChat="BinChat"></Userlist>
					<!-- 聊天区 -->
					<div class="content bottom-li border-right" element-loading-background="rgba(255, 255, 255, 1)"
						element-loading-text="消息加载中..." v-loading="loading">
						<div class="content-top" id="targetbox">
							<div class="more">
								<div v-if="chatList.length==0">~暂无咨询~</div>
								<el-button v-if="showLoadMore&&chatList.length>15" @click="chatMore"
									:loading="loadingMore" round color="#f2f2f2" style="color: #333333;" type="primary">
									加载更多</el-button>
								<div v-if="!showLoadMore&&chatList.length>15">~加载到顶啦~</div>
							</div>
							<!-- 循环 -->
							<div class="chat-li" :id="'new_message' + index" v-for="(item,index) in chatList"
								:key="index">
								<!-- 左边 -->
								<div class="chat-left" v-if="item.type=='user'">
									<div class="time">{{item.time_line}}</div>
									<div class="chat-text">
										<el-image class="chat-left-img" fit="fill" :src="item.from_avatar">
										</el-image>
										<div class="chat-con">
											{{item.content}}
										</div>
									</div>
								</div>
								<!-- 右边 -->
								<div class="chat-left" v-if="item.type=='mine'">
									<div class="time">{{item.time_line}}</div>
									<div class="chat-text">
										<div style="flex: 1;"></div>

										<div class="chat-con chat-con-no" v-if="item.data.length>0"
											style="background: #e8f1f6;">
											<ul class="word-list" v-if="item.data.length>0">
												<li v-for="(items,indexs) in item.data" :key="indexs">{{items.name}}
												</li>
											</ul>
										</div>

										<div v-else class="chat-con" style="background: #e8f1f6;">
											{{item.content}}
										</div>

										<el-image class="chat-left-img" fit="fill" :src="item.from_avatar">
										</el-image>
									</div>
								</div>
							</div>
						</div>
						<div class="emjjo">
							<el-popover v-model:visible="visible" placement="top" :width="400">
								<div class="gb" @click="visible=false"></div>
								<div class="emjjo-list">
									<p @click="binemjjo(item)" v-for="(item,index) in EmojiList" :key="index">{{item}}
									</p>
								</div>
								<template #reference>
									<i @click="showemjjo"></i>
								</template>
							</el-popover>
							<div style="flex: 1;"></div>
							<div class="text" @click="bintext">常用语</div>
							<div class="text" @click="binWord">办事指南</div>
						</div>
						<div class="send">
							<!-- v-if="userInfo.online==1" -->
							<textarea v-model="content" @keyup.enter="submitForm" placeholder="请输入文字内容"></textarea>
							<el-button v-if="hidesend" class="send-tn" @click="submitForm" round
								:color="content?'#4ba6df':'#cccccc'" type="primary">
								发送</el-button>

							<!-- 办事指南 -->
							<!-- <ul class="selectword" v-if="sendFrom.data.data.length>0">
								<li v-for="(item,lindex) in sendFrom.data.data" :key="lindex">
									<p>{{item.name}}</p><i @click="lcolse(index)"></i>
								</li>
							</ul> -->
						</div>
					</div>
					<!-- 用户信息 -->
					<div class="right bottom-li">
						<div class="user-info-li">
							<div class="names">姓名</div>
							<span>{{userInfo.name}}</span>
						</div>
						<div class="user-info-li">
							<div class="names">IP</div>
							<span>{{userInfo.ip}}</span>
						</div>
						<div class="user-info-li">
							<div class="names">地区</div>
							<span>{{City}}</span>
						</div>
						<div class="user-info-li">
							<div class="names">状态</div>
							<span v-if="userInfo.online==1">在线</span>
							<span v-if="userInfo.online==0">已下线</span>
						</div>
						<div class="go-end" v-if="userInfo.online==1">
							<el-button @click="Endword" round color="#4ba6df" type="primary">
								结束对话</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 常用语 -->
		<Texts ref="Texts" @BindText="binemjjo"></Texts>
		<!-- 办事指南 -->
		<Word ref="Word" @SubmitWord="SubmitWord"></Word>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage,
		ElLoading
	} from 'element-plus'

	import emoji from "./emoji.js";
	import Texts from './components/text.vue'
	import Word from './components/word.vue'
	import Userlist from './components/userlist.vue'
	import timeday from "../../js/day.js"
	import {
		request,
		api
	} from "@/api/index";
	export default {
		components: {
			Texts,
			Word,
			Userlist
		},
		data() {
			return {
				hidesend: true,
				keyword: '',
				loading: false,
				showLoadMore: true,
				loadingMore: false,
				content: '',
				EmojiList: [],
				visible: false,
				// 聊天记录
				params: {
					page: 1,
					limit: 20,
					uid: ''
				},
				chatlock: false,
				chatList: [],
				timer: '',
				// websock
				websock: null,
				seddata: {
					type: 'init',
					uid: '',
					name: '',
					avatar: '',
					group: 1
				},
				sendFrom: {
					type: 'chatMessage',
					data: {
						to_id: '',
						to_name: '',
						content: '',
						from_name: '',
						from_id: '',
						from_avatar: '',
						data: []
					}
				},
				userInfo: {},
				kfinfo: {},
				City: '',
				kfId: '',
				readyState: '',

				lockReconnect: false, //是否真正建立连接
				timeoutnum: null, //断开 重连倒计时
				timeout: 3000, //心跳心跳倒计时
				serverTimeoutObj: null, //心跳心跳倒计时
			}
		},
		created() {
			this.EmojiList = emoji
			this.getinfo()
			// 取缓存soket 用户信息
			if (sessionStorage.getItem('userinfo')) {
				this.userInfo = JSON.parse(sessionStorage.getItem('userinfo'))
				this.params.uid = this.userInfo.id
			}
			this.getFirtuser()
		},
		destroyed() {
			this.websock.close() //离开路由之后断开websocket连接
			window.clearInterval(this.timer)

			window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
			window.removeEventListener('unload', e => this.unloadHandler(e))

		},
		mounted() {
			window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
			window.addEventListener('unload', e => this.unloadHandler(e))
		},
		methods: {
			//初始化weosocket
			initWebSocket() {
				// 重置
				this.websock = null
				const wsuri = "wss://www.lslzt.com:8282/";
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onerror = this.websocketonerror;
				this.websock.onopen = this.websocketonopen;
				this.websock.onclose = this.websocketclose;
			},
			// 重新连接
			reconnect() {
				let that = this
				if (that.lockReconnect) {
					return;
				}
				that.lockReconnect = true;
				// 没连接上会一直重连，设置延迟避免请求过多
				that.timeoutnum && clearTimeout(that.timeoutnum);
				that.timeoutnum = setTimeout(function() {
					that.initWebSocket();
					that.lockReconnect = false;
				}, 3000);
			},

			websocketonopen(e) {
				console.log('~连接成功~')
				// 清除时间
				window.clearInterval(this.timer)
				//连接建立之后执行send方法发送数据
				if (this.websock.readyState == 1) {
					this.setTime()
					this.websocketsend(JSON.stringify(this.seddata));
				} else {
					this.websock.close()
				}
			},
			websocketonerror(e) { //连接建立失败重连
				// this.initWebSocket();
			},
			websocketonmessage(e) { //数据接收
				const redata = JSON.parse(e.data);
				var that = this
				if (redata.message_type == 'connect') {
					if (redata.data.user_info.id == this.userInfo.id) {
						this.userInfo = redata.data.user_info
						sessionStorage.setItem('userinfo', JSON.stringify(redata.data.user_info))
						this.params.page = 1
						this.chatlock = false
						this.params.uid = this.userInfo.id
						this.getList()
					}
				}

				// 用户端退出了（下线了）
				if (redata.message_type == 'delUser') {

					// this.userInfo = redata.data.user_info
					// sessionStorage.setItem('userinfo', JSON.stringify(redata.data.user_info))

					ElMessage({
						message: '~用户' + redata.data.user_info.name + '已下线~',
						type: 'error',
					})

					this.hidesend = false
					this.chatList = []
					this.userInfo = {}
					this.City = ''
					sessionStorage.removeItem('userinfo')
				}
				// push到聊天记录
				if (redata.message_type == 'chatMessage') {
					if (redata.data.id == this.userInfo.id) {
						let item = {
							from_avatar: redata.data.avatar,
							content: redata.data.content,
							id: redata.data.id,
							from_name: redata.data.name,
							time_line: redata.data.time,
							type: "user",
							data: null
						}
						this.chatList.push(item)
						this.scrollToBottom()
						this.Read()
					}

				}
				this.$refs.Userlist.Refresh()
				console.log(redata, '数据接收')
			},
			websocketsend(Data) { //数据发送
				if (this.websock.readyState == 1) {
					this.websock.send(Data);
				}
			},
			websocketclose(e) {
				console.log('断开')
				//断开
				if (sessionStorage.getItem('token')) {
					this.reconnect()
					ElMessageBox.confirm(
							'客服已断开连接,请重新连接?',
							'提示', {
								confirmButtonText: '确定',
								type: 'warning',
								showCancelButton: false
							}
						)
						.then(() => {
							location.reload();
						})
				}
			},
			// 删除办事指南
			lcolse(index) {
				this.sendFrom.data.data.splice(index, 1)
			},
			// 发送办事指南
			SubmitWord(val) {
				if (this.userInfo.name) {
					this.sendFrom.data.data = val
					this.submitForm()
				} else {
					ElMessage({
						message: '请选择要发送的用户',
						type: 'warning',
					})
				}
			},
			// 搜索
			search() {
				this.$refs.Userlist.search(this.keyword)
			},
			// 获取默认第一个用户的聊天记录
			getFirtuser() {
				this.$http.post(api.userLists, {
						page: 1,
						limit: 1
					})
					.then((res) => {
						if (res.data.length > 0) {
							this.userInfo = res.data[0]
							sessionStorage.setItem('userinfo', JSON.stringify(res.data[0]))
							this.params.uid = res.data[0].id
							this.Read()
							this.getList()
						}
					})

			},
			// 点击表情
			binemjjo(item) {
				if (this.userInfo.name) {
					this.visible = false
					this.content = item
					this.submitForm()
				} else {
					ElMessage({
						message: '请选择要发送的用户',
						type: 'warning',
					})
				}
			},
			// 点击聊天
			BinChat(item) {
				this.hidesend = true
				this.chatList = []
				this.sendFrom.data.content = ''
				this.sendFrom.data.data = []

				this.chatlock = false
				this.params.page = 1
				let data = JSON.parse(item)
				this.params.uid = data.id
				this.userInfo = data
				sessionStorage.setItem('userinfo', JSON.stringify(data))
				this.getList()
				this.Read()

			},
			// 发送消息
			submitForm() {

				// 去掉换行符
				this.content = this.content.replace(/[\r\n]/g, "")
				if (this.content == '' && this.sendFrom.data.data.length == 0) {
					ElMessage({
						message: '请输入文字内容',
						type: 'warning',
					})
				} else {
					// if (this.userInfo.online == 1) {
					this.sendFrom.data.content = this.content
					this.sendFrom.data.to_id = this.userInfo.id
					this.sendFrom.data.to_name = this.userInfo.name
					// push 到聊天记录
					let item = {
						from_avatar: this.kfinfo.avatar,
						content: this.content,
						id: this.kfinfo.id,
						from_name: this.kfinfo.name,
						time_line: timeday.s_time(),
						type: "mine",
						data: this.sendFrom.data.data
					}
					this.chatList.push(item)
					this.websocketsend(JSON.stringify(this.sendFrom));
					this.sedEnd()

					// 清空
					this.content = ''
					this.sendFrom.data.content = ''
					this.sendFrom.data.data = []
					// } else {
					// 	ElMessage({
					// 		message: '~该用户已离线~',
					// 		type: 'error',
					// 	})
					// 	// 清空
					// 	this.content = ''
					// 	this.sendFrom.data.content = ''
					// 	this.sendFrom.data.data = []
					// }

				}
			},
			// 发送滚动到底
			sedEnd() {
				var that = this
				if (this.chatList.length > 0) {
					this.$nextTick(() => {
						setTimeout(function() {
							document.getElementById('new_message' + (that.chatList.length - 1))
								.scrollIntoView() //滚动到指定位置
						}, 200)
					})
				}
			},
			// 轮询心跳
			setTime() {
				var that = this
				var data = {
					message_type: 'ping',
					txt: ''
				}
				this.timer = window.setInterval(() => {
					if (that.websock.readyState == 1) {
						that.websocketsend(JSON.stringify(data));
					} else {
						that.reconnect();
					}
				}, 3000)

			},
			// 获取客服详情
			getinfo() {
				this.$http.post(api.info)
					.then((res) => {
						this.kfId = res.data.id
						this.seddata.uid = 'KF' + res.data.id
						this.seddata.name = res.data.name
						this.seddata.avatar = res.data.avatar
						this.kfinfo = res.data
						// 发送
						this.sendFrom.data.from_id = 'KF' + res.data.id
						this.sendFrom.data.from_name = res.data.name
						this.sendFrom.data.from_avatar = res.data.avatar

						this.initWebSocket();
					})
			},

			// 加载更多聊天记录
			chatMore() {
				this.loadingMore = true
				this.getList()
			},
			// 已读
			Read() {
				this.$http.post(api.resetRead, {
						uid: this.userInfo.id
					})
					.then((res) => {
						this.$refs.Userlist.Refresh()
					})
			},
			// 获取聊天记录
			async getList() {
				if (this.chatlock) {
					return
				}
				this.chatlock = true
				this.getdata()
			},
			// 获取接口
			async getdata() {
				var that = this
				this.loading = true
				this.showLoadMore = true;
				this.$http.post(api.chatLog, this.params)
					.then((res) => {
						const list = this.chatList
						let page = this.params.page
						let chatlock;
						if (res.data.length < this.params.limit) {
							chatlock = true
							this.showLoadMore = false
						} else {
							this.showLoadMore = true
							page += 1
							chatlock = false
						}
						let listdata = this.params.page > 1 ? res.data.reverse().concat(this.chatList) : res.data
							.reverse().concat(this.chatList)
						this.chatList = listdata
						this.params.page = page
						this.chatlock = chatlock
						this.loadingMore = false
						this.scrollToBottom()
						this.getCity()
						setTimeout(function() {
							that.loading = false
						}, 800)
					})
			},
			// 获取地区
			getCity() {
				this.$http.post(api.getCity, {
						ip: this.userInfo.ip
					})
					.then((res) => {
						this.City = res.data.city
					})
			},
			// 关闭浏览器 结束用户
			beforeunloadHandler(e) {
				this._beforeUnload_time = new Date().getTime();

			},
			unloadHandler(e) {
				this._gap_time = new Date().getTime() - this._beforeUnload_time;
				debugger
				//判断是窗口关闭还是刷新

				//关闭浏览器断开链接
				// let item = {
				// 	type: 'closeUser',
				// 	kf_id: 'KF' + this.kfId
				// }
				// this.websocketsend(JSON.stringify(item));
				// this.userInfo = {}
				// this.City = ''
				// sessionStorage.removeItem('userinfo')

				// return
				if (this._gap_time <= 5) {
					//关闭浏览器断开链接
					// let item = {
					// 	type: 'closeUser',
					// 	kf_id: 'KF' + this.kfId
					// }
					// this.websocketsend(JSON.stringify(item));
					// this.userInfo = {}
					// this.City = ''
					// sessionStorage.removeItem('userinfo')
				} else {
					console.log('刷新')
				}
			},
			// 结束对话
			Endword() {
				let that = this
				ElMessageBox.confirm(
						'是否确定结束对话?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						let item = {
							type: 'kf_endtalk',
							uid: that.userInfo.id
						}
						that.websocketsend(JSON.stringify(item));
						ElMessage({
							type: 'success',
							message: '结束成功',
						})
						that.chatList = []
						that.$refs.Userlist.Refresh()
						that.userInfo = {}
						that.City = ''
						sessionStorage.removeItem('userinfo')
					})
					.catch(() => {
						// 取消
					})
			},

			// 下班退出
			signout() {
				ElMessageBox.confirm(
						'是否确定下班退出?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						let item = {
							type: 'closeUser',
							kf_id: 'KF' + this.kfId
						}
						this.websocketsend(JSON.stringify(item));

						sessionStorage.removeItem('token')
						this.userInfo = {}
						this.City = ''
						sessionStorage.removeItem('userinfo')
						window.clearInterval(this.timer)
						this.websock.close() //离开路由之后断开websocket连接
						this.$router.push('/login')
						ElMessage({
							type: 'success',
							message: '下班成功',
						})
					})
					.catch(() => {
						// 取消
					})
			},
			// 办事指南显示
			binWord() {
				this.$refs.Word.show()
			},
			// 滚动到底
			scrollToBottom: function() {
				var that = this
				if (this.chatList.length > 0) {
					if (this.params.page > 2) {
						this.$nextTick(() => {
							document.getElementById('new_message19').scrollIntoView() //滚动到指定位置
						})
					} else {
						this.$nextTick(() => {
							setTimeout(function() {
								document.getElementById('new_message' + (that.chatList.length - 1))
									.scrollIntoView() //滚动到指定位置
							}, 200)
						})
					}
				}
			},
			// 常用语
			bintext() {
				this.$refs.Texts.show()
			},
			// 表情显示
			showemjjo() {
				this.visible = !this.visible
			},
		}
	}
</script>

<style lang="scss">
	.gb {
		float: right;
		width: 25px;
		cursor: pointer;
		height: 25px;
		margin-bottom: 10px;
		cursor: pointer;
		background: url(../../assets/img/gb.png);
		background-size: 100% 100%;
		display: inline-block;
	}

	.chat-con-no {
		padding: 0 10px !important;
	}

	.state {
		margin-left: 10px;
		font-size: 12px;
	}

	.selectword {
		width: 300px;
		position: absolute;
		bottom: 10px;
		border-top: 1px solid #f2f2f2;
		border-right: 1px solid #f2f2f2;
		height: 100px;
		overflow-y: scroll;
		left: 0;
		padding: 10px;

		li {
			line-height: 30px;
			width: 100%;
			display: flex;
			align-items: center;

			i {
				width: 22px;
				height: 22px;
				cursor: pointer;
				background: url(../../assets/img/guanbi.png);
				background-size: 100% 100%;
				display: inline-block;
			}

			p {
				flex: 1;
				padding-right: 10px;
				text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}
	}

	.word-list {

		li:nth-last-child(1) {
			border-bottom: none;
		}

		li {
			line-height: 45px;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			line-clamp: 1;
			-webkit-box-orient: vertical;
			border-bottom: 1px solid #dedede;
		}
	}

	.more {
		text-align: center;
	}

	.chat-text {
		width: 100%;
		display: flex;

		.chat-con {
			max-width: 320px;
			padding: 10px;
			margin: 0 15px;
			border-radius: 10px;
			background: #f5f5f5;
		}
	}

	.chat-li {
		width: 100%;

		.chat-left {
			width: 100%;

			.time {
				padding: 20px 0;
				text-align: center;
				color: #999999;
			}
		}
	}

	.chat-left-img {
		width: 40px;
		height: 40px;
		border-radius: 50%;
	}

	.emjjo-list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		overflow-y: scroll;
		max-height: 300px;

		p {
			cursor: pointer;
			width: 20%;
			text-align: center;
			font-size: 20px;
			margin-bottom: 10px;
		}
	}

	.send-tn {
		position: absolute;
		right: 20px;
		bottom: 20px;
		width: 80px;
	}

	.content-top {
		border-bottom: 1px solid #F2f2f2;
		height: 454px;
		padding: 20px;
		overflow-y: scroll;
	}

	.send {
		height: 190px;
		position: relative;
		padding: 0 20px;

		textarea {
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
			height: 190px;
		}
	}

	.emjjo {
		padding: 0 20px;
		height: 40px;
		display: flex;
		align-items: center;

		.text {
			margin-left: 50px;
			cursor: pointer;
		}

		i {
			cursor: pointer;
			width: 25px;
			height: 25px;
			display: inline-block;
			background: url(../../assets/img/emjjo.png);
			background-size: 100% 100%;
		}
	}

	.go-end {
		position: absolute;
		bottom: 0px;
		padding: 20px;
		width: 100%;

		button {
			width: 100%;
		}
	}

	.user-info-li {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 12px 20px;

		.names {
			flex: 1;
		}
	}

	.body {
		width: 100%;
		height: 100vh;

		.user-info {
			flex: 1;
			font-size: 16px;
			color: #FFFFFF;
			text-align: center;
		}

		.body-bg {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			flex-wrap: wrap;
			overflow-y: scroll;
			width: 100%;

			.constant {
				width: 1200px;
				display: flex;
				height: 750px;
				flex-wrap: wrap;
				background: #FFFFFF;
				box-shadow: 0 5px 40px 0 #777777;

				.bottom {
					width: 100%;
					overflow: hidden;
					height: 684px;
					display: flex;

					.content {
						height: 684px;
					}

					.chat-box {
						.chat-list {
							max-height: 684px;
							overflow-y: auto;

							.current {
								background: #eff0f1;

								span {
									background: #4ba6df;
								}
							}

							li {
								position: relative;
								width: 100%;
								cursor: pointer;
								padding: 12px 20px;
								height: 74px;
								display: flex;
								cursor: pointer;
								font-size: 14px;
								align-items: center;

								.num {
									width: 20px;
									height: 20px;
									text-align: center;
									line-height: 20px;
									font-size: 10px;
									text-align: center;
									background: red;
									color: #FFFFFF;
									border-radius: 50%;
								}

								.name {
									flex: 1;
								}

								span {
									position: absolute;
									left: 0;
									top: 50%;
									margin-top: -20px;
									width: 3px;
									height: 40px;
									display: inline-block;
									background: #FFFFFF;
								}

								.img {
									width: 40px;
									overflow: hidden;
									margin-right: 10px;
									border-radius: 50%;
									height: 40px;

									img {
										width: 40px;
										height: 40px;
									}
								}
							}
						}
					}

					.bottom-li {
						height: 684px;
					}

					.border-right {
						border-right: 1px solid #f2f2f2;
					}
				}

				.top {
					background: #4ba6df;
					height: 66px;
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;

					.top-li {
						display: flex;
						align-items: center;
						padding: 20px;
						box-sizing: border-box;

						.login-out {
							height: 35px;
							background: #FFFFFF;
							width: 90px;
							cursor: pointer;
							border-radius: 200px;
							line-height: 35px;
							text-align: center;
						}

						.user {
							flex: 1;
							display: flex;
							flex-wrap: wrap;
							color: #FFFFFF;
							align-items: center;

							.user-img {
								width: 40px;
								height: 40px;
								margin-right: 10px;
								border-radius: 50%;
							}
						}

						.search {
							background: #FFFFFF;
							height: 35px;
							width: 100%;
							padding-left: 15;
							padding-right: 10px;
							display: flex;
							align-items: center;
							border-radius: 200px;

							i {
								margin-right: 5px;
								cursor: pointer;
							}

							input {
								font-size: 14px;
								flex: 1;
								height: 35px;
							}
						}
					}
				}

				.left {
					width: 319px;
				}

				.content {
					width: 601px;
				}

				.right {
					position: relative;
					width: 280px;
				}
			}
		}
	}
</style>
