export default[
	"😀",
	"😁",
	"😂",
	"😃",
	"😄",
	"😅",
	"😆",
	"😇",
	"😈",
	"😉",
	"😊",
	"😋",
	"😌",
	"😍",
	"😎",
	"😏",
	"😐",
	"😑",
	"😒",
	"😓",
	"😔",
	"😕",
	"😖",
	"😗",
	"😘",
	"😙",
	"😚",
	"😛",
	"😜",
	"😝",
	"😞",
	"😟",
	"😠",
	"😡",
	"😢",
	"😣",
	"😤",
	"😥",
	"😦",
	"😧",
	"😨",
	"😩",
	"😪",
	"😫",
	"😬",
	"😭",
	"😮",
	"😯",
	"😰",
	"😱",
	"😲",
	"😳",
	"😴",
	"😵",
	"😶",
	"😷",
	"😸",
	"😹",
	"😺",
	"😻",
	"😼",
	"😽",
	"😾",
	"😿",
	"🙀",
	"🙁",
	"🙂",
	"🙃",
	"🙄",
]